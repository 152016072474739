import React from 'react';
import './styles/Philosophy.scss';
import TextLoop from 'react-text-loop';

export const PhilosophySection = () => {
  return (
    <div className="section philosophy-main" id="philosophy">
      <p className="philosophy-title">My Philosophy</p>
      <div className="philosophy">
        <div className="container">
          <h1>
            <span className="changing">Changing </span>
            <TextLoop
              interval={2500}
              fade
              children={['Place', 'Time', 'Thoughts', 'Future']}
            />
          </h1>
          <p>
            Moving in space and time, reading, traveling or talking to other
            people has always given me the energy and motivation to think and
            redesign the present to improve it for the future.
          </p>
        </div>
      </div>
    </div>
  );
};
